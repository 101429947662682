import { http } from '../helpers/easyindustriaapi/config'; // importa módulo http

// Função para buscar os dados do dashboard a partir da API
export default class DashboardController {
    constructor() {
        // this.currentUser = JSON.parse(localStorage.getItem('user'));
        this.currentEmpresa = JSON.parse(localStorage.getItem('currentEmpresa'));
    }

    async getStatistics() {
        try {
            // Faz a requisição GET para a API
            const response = await http.get(`/dashboard?empresa_id=${this.currentEmpresa.id}`);
            // Retorna os dados recebidos da API
            return response.data;
        } catch (error) {
            console.log(error);
            throw this.handleError(error);
        }
    }
}