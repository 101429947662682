<script>
import {http} from "../../../helpers/easyindustriaapi/config";
import {
  layoutMethods
} from "@/state/helpers";
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import Stat from "./widget";
import RevenueAnalytics from "./revenue";
import SalesAnalytics from "./sales-analytics";
import EarningReport from "./earning";
import Sources from "./sources";
import RecentActivity from "./recent-activity";
import RevenueLocation from "./revenue-location";
import Chat from "./chat";
import Transaction from './transaction';
import DashboardController from '@/controller/DashboardController';
import Swal from "sweetalert2";

/**
 * Dashboard component
 */
export default {
  components: {
    Layout,
    PageHeader,
    Stat,
    RevenueAnalytics,
    SalesAnalytics,
    EarningReport,
    Sources,
    RecentActivity,
    RevenueLocation,
    Chat,
    Transaction
  },
  data() {
    return {
      title: "Dashboard",
      items: [
        {
          text: "EasySaude"
        },
        {
          text: "Dashboard",
          active: true
        }
      ],
      loader: this.$store ? this.$store.state.layout.loader : {} || {},
      dashboardData: {
        statistics: null,
      }
    };
  },
  created() {
    this.currentUser = JSON.parse(localStorage.getItem('user'));
    this.dashboardController = new DashboardController();
    this.changeLoaderValue({
      loader: true,
    });
  },
  mounted() {
    // this.getData();
    this.loadDashboardData();
  },
  methods: {
    ...layoutMethods,
    async loadDashboardData() {
      Swal.fire({
        title: 'Aguarde...',
        text: 'Carregando dados do dashboard...',
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading(); // Exibe o loader enquanto a requisição está em andamento
        }
      });
      try {
        this.changeLoaderValue({ loader: true });
        const [
          statistics
        ] = await Promise.all([
          this.dashboardController.getStatistics(),
        ]);
        this.dashboardData = {
          statistics
        };
      } catch (error) {
        Swal.close();
        Swal.fire('Oops...', error.message, 'error');
        this.$toast.error(error.message);
      } finally {
        this.changeLoaderValue({ loader: false });
        Swal.close();
      }
    },
    async getData() {
      try {
        await http.get('user/'+this.currentUser.id)
          .then(res => {
            console.log(res);
          });
      } catch (error) {
        if (error.response && error.response.status === 403) {
          this.error('Usuário não tem permissão!');
        }

        if (error.response && error.response.status === 401) {
          this.error('Usuário não autenticado!');
        }
      }
    }
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-xl-8">
        <Stat v-if="dashboardData.statistics" :statistics="dashboardData.statistics" />
        <RevenueAnalytics />
      </div>
      <div class="col-xl-4">
        <SalesAnalytics />
        <EarningReport />
      </div>
    </div>
    <div class="row">
      <div class="col-lg-4">
        <Sources />
      </div>
      <div class="col-lg-4">
        <RecentActivity />
      </div>
      <div class="col-lg-4">
        <RevenueLocation />
      </div>
    </div>
    <div class="row">
      <div class="col-lg-4">
        <Chat />
      </div>
      <div class="col-lg-8">
        <Transaction v-if="dashboardData.statistics" :statistics="dashboardData.statistics" />
      </div>
    </div>
  </Layout>
</template>